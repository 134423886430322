
<template>
<div class="bg">
    <div class="title">
<img src="../../assets/nyimg/hd5.jpg" alt="" style="width: 100%;">
    </div>
    <div class="bottom">
<div v-for="(item,index) in boxlist" :key="index" class="btmbox" @click="$router.push(item.path)">
    <div class="imgbox">
         <img :src="require('@/assets/nyimg/'+item.icon+'.png')" alt="">
    </div>
   <p style="font-size: 18px;padding:0 20px;margin:0">{{ item.title }}</p>
</div>
    </div>
</div>
</template>

<script>
import router from '../../router';

export default {
  name: '',
  components: {},
  data () {
    return {
        boxlist:[
{
    title:'小气候站数据',
    icon:'hd6',
    path:'/station'
},
{
    title:'服务材料查看',
    icon:'hd7',
    path:'/cailiao'
},{
    title:'田间调查数据',
    icon:'hd8',
    path:'/tjdc'
}
        ]
    }
  },
  created () { },
  mounted () { },
  methods: { 
    topath(data){
        router.path(data)
    }
   },
  computed: {}
}
</script>
<style scoped lang='scss'>
.title{
    overflow: hidden;
    text-align: center;
    box-sizing: border-box;
    h1{
        text-shadow:5px 0 2px #000
    }
    
}
.bg{
    background-image: url('~@/assets/images/shbg.jpg');
    width: 100%;
    height:100%;
    background-size:100% 100% ;
    overflow: auto;
}
.bottom{
    display: flex;
    flex-wrap: wrap;
    .btmbox{
        width: 44%;
        padding:20px;
        box-sizing: border-box;
        background: rgba(0,0,0,0.3);
        margin:3%;
        text-align: center;
        display: flex;
        flex-direction:column;
        align-items:center;
        border-radius:5px
    }
    .imgbox{
        width:8vh;
        line-height: 5vh;
        align-items:center
    }
    img{
    width:100%
    }
   
}
</style>
