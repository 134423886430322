// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/images/shbg.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".title[data-v-0a118295]{overflow:hidden;text-align:center;box-sizing:border-box}.title h1[data-v-0a118295]{text-shadow:.00667rem 0 .00267rem #000}.bg[data-v-0a118295]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");width:100%;height:100%;background-size:100% 100%;overflow:auto}.bottom[data-v-0a118295]{-webkit-flex-wrap:wrap;flex-wrap:wrap}.bottom[data-v-0a118295],.bottom .btmbox[data-v-0a118295]{display:-webkit-box;display:-webkit-flex;display:flex}.bottom .btmbox[data-v-0a118295]{width:44%;padding:.02667rem;box-sizing:border-box;background:rgba(0,0,0,.3);margin:3%;text-align:center;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;flex-direction:column;border-radius:.00667rem}.bottom .btmbox[data-v-0a118295],.bottom .imgbox[data-v-0a118295]{-webkit-box-align:center;-webkit-align-items:center;align-items:center}.bottom .imgbox[data-v-0a118295]{width:8vh;line-height:5vh}.bottom img[data-v-0a118295]{width:100%}", ""]);
// Exports
module.exports = exports;
